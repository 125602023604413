<template>
	<div>
		<footer>
			<div class="row">
				<div class="footer-content">
					<div class="columns column3">
						<div class="logo" />
						<div v-parse-links v-html="defaults[locale].website.footerContent" />
					</div>
					<div class="columns column3">
						<h3>{{ $t('footerLocation') }}</h3>
						<p>
							<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
							<br />
							<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
							<br />
							<span>{{ defaults[locale].website.country }}</span>
							<br />
						</p>
					</div>
					<div class="columns column3">
						<h3>{{ $t('footerContact') }}</h3>
						<div>
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon icon="fa-regular fa-phone" size="1x" />
								<span>{{ defaults[locale].website.phone }}</span>
							</a>
							<br />
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon icon="fa-regular fa-envelope" size="1x" />
								<span>{{ defaults[locale].website.email }}</span>
							</a>
							<social-media :socials="socials" />
						</div>
					</div>
					<div class="columns column3">
						<h3>{{ $t('footerNavigate') }}</h3>
						<p class="footer-nav">
							<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
								{{ item.header }} <br />
							</nuxt-link>
						</p>
					</div>
				</div>
			</div>
			<div class="row author">
				<div class="columns column12 align-center">
					<p class="align-left">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
					<p class="align-right">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1">|</span>
						</span>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: url('~/assets/images/footer-bg-icon.png') repeat center center;
	color: $body-color;
	padding: 100px 0 25px;
	font-size: 15px;

	.footer-content {
		background: $footer-background-color;
		padding: 50px 30px;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
	}

	svg {
		color: $cta-color;
		margin: 0 5px 0 0;
	}

	.columns {
		padding: 0 20px;
	}
}

a {
	color: $footer-link-color;
	text-decoration: none;

	&:hover {
		color: $cta-color;
	}
}

.logo {
	background: url('~/assets/images/logo-hotel-aura-del-mar-color.png') no-repeat center center;
	background-size: 225px;
	width: 225px;
	height: 62px;
}

.author {
	padding: 20px 0;

	.column12 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	p {
		margin: 0;
		font-size: 12px;
		color: #fff;
		text-align: left;
	}

	a {
		color: #fff;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}

	footer .row:last-child .columns:last-child a {
		margin: 0;
	}
}

@media (max-width: 600px) {
	footer {
		padding: 100px 0 75px;

		.footer-content {
			padding: 30px 20px;
		}
	}

	.author {
		.column12 {
			flex-wrap: wrap;
		}

		p {
			text-align: center;
			width: 100%;
			margin: 5px 0;
		}
	}
}
</style>
